import React from 'react';
import styled from 'styled-components';
import { SEO } from '../components';
import Layout from '../components/Layout/Layout';
import { rem, theme } from '../theme';

const AboutSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  padding: 48px 32px;

  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding: 48px 148px;
  }
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  font-size: ${rem(2)};
  text-align: justify;
  padding-top: 10px;
  padding-bottom: 10px;
  & > a {
    color: ${theme.colors.topbar.link_active_button} !important;
    text-decoration: none !important;
    font-size: ${rem(3)};
    display: contents;
  }
  & > b {
    display: contents;
  }
  margin: 0px;
`;

const OpportunitiesInfo = styled.div`
  & p {
    font-size: ${rem(6)};
  }

  text-align: center;
`;

const OpportunitiesBox = styled.div`
  background-color: #f2f2f2;
  color: ${theme.colors.font.secondary};
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: ${rem(3)};
  line-height: 20px;
  padding: 48px 48px;

  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding: 48px 148px;
  }

  & > div {
    width: 100%;
    text-align: center;
  }
`;

const BigText = styled.div`
  font-size: ${rem(8)};
  font-weight: 700;
  margin-top: 18px;
  color: ${theme.colors.primary};
`;

const CareersPage = () => {
  return (
    <Layout>
      <SEO title="Careers" />
      <AboutSection>
        <h1>Create with US!</h1>
        <TextColumn>
          You are cordially invited to join (Y)our team. As a full fledged team
          member you will receive admittance to a group of highly motivated and
          knowledgable leaders. Acting within this group you will quickly gain
          experience and competence that will allow you to grow in either one of
          the directions, as project and/or technological competence leader. A
          partnership approach with a focus on mutual benefits within a friendly
          and group-centric atmosphere will allow you to contribute almost
          instantly to (Y)our team.
        </TextColumn>
        <OpportunitiesInfo>
          <p>
            Please see the Partnering Opportunities below and should you value a
            place as focused on personal and group development as we are on
            client needs we are eager to get to know you.
          </p>
        </OpportunitiesInfo>
      </AboutSection>
      <OpportunitiesBox>
        <div>
          Currently no Partnership Opportunities but feel free to contact us
          anyway!
        </div>
      </OpportunitiesBox>
      <AboutSection>
        <TextColumn>
          Please feel free to reach out through our{' '}
          <a href="mailto:partnering@b4limits.pl">partnering[at]b4limits.pl</a>{' '}
          with your written experience history in a form of a CV. While your CV
          is nothing but a menu of your experience to date with, possibly,
          thoughts and golas for your future, we hope that you will share it
          with us so that we can create (Y)our history together. We are not
          formalists we do have respect the law and as such we ask you to
          include all customary consent statements on your enclosed materials.
        </TextColumn>
        <TextColumn>
          Once you take the moment to reach out to us we will certainly respond
          via email and/or call and guide you towards your next steps in getting
          to know each other better. We hope to hear from you soon!
        </TextColumn>
        <BigText>CU soon!</BigText>
      </AboutSection>
    </Layout>
  );
};

export default CareersPage;
